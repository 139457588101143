<template>
  <CCard ref="swiper" class="card-custom shadow-none swiper" color="second-card-bg">
    <CCardBody class="swiper-wrapper">
      <ticker-name
        class="swiper-slide"
        key-col="pair"
      />

      <ticker-item
        class="swiper-slide"
        color="#FFC107"
        :title="$t('screener.graphsView.open')"
      />
      <ticker-item
        class="swiper-slide"
        color="#20A8D8"
        :title="$t('screener.graphsView.current')"
      />
      <ticker-item
        key-col="closePct"
        class="swiper-slide"
        color="#C8CED3"
        :title="$t('screener.graphsView.change')"
      />
      <ticker-item
        key-col="volatility"
        color="#E83E8C"
        :title="$t('screener.graphsView.volat')"
        :tooltip="$t('screener.graphsView.volatDesc')"
        class="swiper-slide"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import TickerItem from "@/components/screener/graphs/TickerItem"
import TickerName from "@/components/screener/graphs/TickerName"
import Swiper from "swiper";

export default {
  name: "GraphsViewTicker",
  components: {
    TickerName,
    TickerItem
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.swiper) {
        this.swiper = new Swiper(this.$refs.swiper, {
          slidesPerView: 'auto',
          observer: true,
          observeParents: true,
          observeSlideChildren: true,
          on: {
            init(swiper) {
              setTimeout(() => {
                try {
                  swiper.update()
                  // eslint-disable-next-line no-empty
                } catch (e) {}
              }, 300)
            }
          }
        })
      }
    })
  },
}
</script>

<style lang="scss" scoped>

.wrap {
  font-size: 0.75rem;
}

.swiper {
  padding: .25rem .875rem;
  margin: 0;
  width: 100%;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @include media-breakpoint-up(lg) {
    padding: .5rem 1.25rem;
    width: fit-content;
  }
}

.swiper-wrapper {
  padding: 0;
  align-items: center;
  width: 100%;
}

.swiper-slide {
  width: fit-content;
  margin-right: .625rem;

  @include media-breakpoint-up(md) {
    margin-right: 1rem;
  }

  &:first-child {
    margin-right: 1rem;

    @include media-breakpoint-up(md) {
      margin-right: 2rem;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}
</style>
