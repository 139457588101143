var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100 d-flex flex-column" },
    [
      _c(
        "portal",
        {
          attrs: {
            disabled: !_vm.isTablet && !_vm.isMobile,
            to: "screener-header",
          },
        },
        [
          _c(
            "div",
            { staticClass: "switch-wrap d-flex align-items-center" },
            [
              _c("CSwitch", {
                staticClass: "switch flex-shrink-0",
                attrs: {
                  color: "primary",
                  shape: "pill",
                  checked: _vm.isCollapsed,
                  id: "switch",
                },
                on: {
                  "update:checked": function ($event) {
                    _vm.isCollapsed = $event
                  },
                },
              }),
              _c(
                "label",
                { staticClass: "switch-label mb-0", attrs: { for: "switch" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isMobile
                          ? _vm.$t("screener.graphsView.panelMobile")
                          : _vm.$t("screener.graphsView.panel")
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "list-wrap d-flex overflow-hidden flex-grow-1" },
        [
          _c(
            "div",
            { staticClass: "w-100 d-flex flex-column" },
            [
              _c(
                "CCollapse",
                { attrs: { show: _vm.isCollapsed, duration: 0 } },
                [
                  _c(
                    "CRow",
                    { staticClass: "no-gutters" },
                    [
                      _c(
                        "CCol",
                        { attrs: { col: "12" } },
                        [
                          _c("view-header", {
                            staticClass: "view-header",
                            attrs: { settings: _vm.settings },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { col: "12", lg: "9" } },
                        [_c("ticker", { staticClass: "ticker" })],
                        1
                      ),
                      _vm.isDesktop
                        ? _c(
                            "CCol",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-end",
                              attrs: { col: "12", lg: "3" },
                            },
                            [
                              _c("CSelect", {
                                staticClass: "number-of-charts-select m-0",
                                attrs: {
                                  label: `${_vm.$t(
                                    "screener.graphsView.numberOfCharts"
                                  )}:`,
                                  value: _vm.numberOfCharts,
                                  options: _vm.numberOfChartsOptions,
                                  horizontal: {
                                    label: "col-9 text-right",
                                    input: "col-3",
                                  },
                                  placeholder: "Выберите",
                                  size: "sm",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    _vm.numberOfCharts = $event
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex-grow-1 overflow-hidden d-flex" },
                [
                  _c("virtual-list", {
                    directives: [
                      {
                        name: "move-to-top",
                        rawName: "v-move-to-top",
                        value: _vm.vMoveToTopOptions,
                        expression: "vMoveToTopOptions",
                      },
                    ],
                    staticClass:
                      "overflow-auto w-100 watch-selector-parent-scroll",
                    attrs: {
                      "data-key": "id",
                      "data-sources": _vm.dataSources,
                      "data-component": _vm.ItemComponent,
                      "estimate-size": 100,
                      keeps: 15,
                      "extra-props": { openCandles: _vm.openCandles },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("CandlesGraphModal", {
        ref: "candles",
        attrs: {
          "is-minutes": true,
          settings: _vm.settings,
          "four-way-switch": "",
          rows: _vm.dataSources,
          "client-id": _vm.clientId,
          "hide-notification-modal": _vm.isActiveFutures,
          "is-futures": _vm.isActiveFutures,
        },
        on: {
          close: function ($event) {
            return _vm.$emit("closeGraph")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }