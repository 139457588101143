var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "font-weight-bold text-uppercase" },
    [
      _vm._v(" " + _vm._s(_vm.$t("screener.graphsView.ticker")) + " "),
      _c("CIcon", {
        staticClass: "switcher",
        class: {
          _active: _vm.sorting,
          _reverse: _vm.sorting === "asd",
        },
        attrs: { width: "0.7rem", name: "arrow-strong" },
        nativeOn: {
          click: function ($event) {
            return _vm.changeSort.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }