<template>
  <div class="w-100 d-flex flex-column">
    <portal
      :disabled="!isTablet && !isMobile"
      to="screener-header"
    >
      <div class="switch-wrap d-flex align-items-center">
        <CSwitch
          color="primary"
          shape="pill"
          :checked.sync="isCollapsed"
          class="switch flex-shrink-0"
          id="switch"
        />
        <label for="switch" class="switch-label mb-0">
          {{ isMobile ? $t('screener.graphsView.panelMobile') : $t('screener.graphsView.panel') }}
        </label>
      </div>
    </portal>

    <div class="list-wrap d-flex overflow-hidden flex-grow-1">
      <div class="w-100 d-flex flex-column">
        <CCollapse :show="isCollapsed" :duration="0">
          <CRow class="no-gutters">
            <CCol col="12">
              <view-header class="view-header" :settings="settings"/>
            </CCol>

            <CCol col="12" lg="9">
              <ticker class="ticker"/>
            </CCol>

            <CCol
              v-if="isDesktop"
              col="12"
              lg="3"
              class="d-flex align-items-center justify-content-end"
            >
              <CSelect
                class="number-of-charts-select m-0"
                :label="`${$t('screener.graphsView.numberOfCharts')}:`"
                :value.sync="numberOfCharts"
                :options="numberOfChartsOptions"
                :horizontal="{
                  label: 'col-9 text-right',
                  input: 'col-3'
                }"
                placeholder="Выберите"
                size="sm"
              />
            </CCol>
          </CRow>
        </CCollapse>

        <div class="flex-grow-1 overflow-hidden d-flex">
          <virtual-list
            v-move-to-top="vMoveToTopOptions"
            class="overflow-auto w-100 watch-selector-parent-scroll"
            data-key="id"
            :data-sources="dataSources"
            :data-component="ItemComponent"
            :estimate-size="100"
            :keeps="15"
            :extra-props="{openCandles: openCandles}"
          />
        </div>
      </div>
    </div>

    <CandlesGraphModal
      ref="candles"
      :is-minutes="true"
      :settings="settings"
      four-way-switch
      :rows="dataSources"
      :client-id="clientId"
      :hide-notification-modal="isActiveFutures"
      :is-futures="isActiveFutures"
      @close="$emit('closeGraph')"
    />
  </div>
</template>

<script>
import ViewHeader from "@/components/screener/ViewHeader"
import Ticker from "@/components/screener/graphs/Ticker"
import screenerGraphAdapter from "@/assets/js/screenerGraphAdapter"
import axios from "axios"
import VirtualList from 'vue-virtual-scroll-list'
import ItemComponent from "@/components/screener/graphs/ItemComponent"
import toastr from 'toastr'
import CandlesGraphModal from "@/components/screener/tables/CandlesGraphModal"
import { mapGetters, mapActions } from "vuex";
import { createWatcherExhangeAndScreen } from "@/mixins/watch-exhange-and-screen"
import {CLIENT_ID_CANDLES_BINANCE_FUTURES, CLIENT_ID_DEFAULT, SCREENER_PAGE_FUTURES} from "@/shared/constants";

export default {
  name: "GraphsView",
  mixins: [
    createWatcherExhangeAndScreen(({ getData }) => getData()),
  ],
  components: {
    ViewHeader,
    Ticker,
    VirtualList,
    CandlesGraphModal
  },
  props: {
    settings: Object,
    isPaused: {
      type: Boolean,
      default: false,
    },
  },
  updated(){
    this.$root.$on('ChartItemGraphScreenerClick', (title) => {
      this.openCandles(title)
    })
  },
  data() {
    return {
      isCollapsed: this.$store.state.settings?.graphsView?.isCollapsed,
      ItemComponent: ItemComponent,
      adapter: screenerGraphAdapter,
      loadingData: false,
      timer: null,
      timerIntervalDefault: 10000, // 60 sec
      cancelEvent: null,
      numberOfChartsOptions: [2, 3, 4, 5, 6, 7, 8],
      numberOfCharts: 4
    }
  },
  computed: {
    user(){
      return this.$store.state.user.userData
    },
    ...mapGetters({
      isBrowserTabActive: 'isBrowserTabActive',
      isDesktop: 'mediaQuery/isDesktop',
      isTablet: 'mediaQuery/isTablet',
      isMobile: 'mediaQuery/isMobile',
      isLaptop: 'mediaQuery/isLaptop',
      isGuest: 'user/isGuest'
    }),
    vMoveToTopOptions() {
      if (this.isMobile || this.isTablet) {
        return {
          position: {bottom: 80, right: 20}
        }
      } else return {
          position: {bottom: 80, right: -35}
        }
    },
    active() {
      return this.settings.graphsActivePeriod
    },
    dataSources() {
      switch (true) {
        case this.isMobile:
          return this.adapter.getDataGroup(1)
        case this.isTablet:
          return this.adapter.getDataGroup(2)
        case this.isLaptop:
          return this.adapter.getDataGroup(3)
        default:
          return this.adapter.getDataGroup(this.numberOfCharts)
      }
    },
    clientId() {
      let clientId = CLIENT_ID_DEFAULT

      if (this.isActiveFutures) {
        clientId = CLIENT_ID_CANDLES_BINANCE_FUTURES
      }

      return clientId
    },
    isActiveFutures() {
      return this.currentScreenAndExchange.screen === SCREENER_PAGE_FUTURES
    }
  },
  created() {
    this.getData()
    this.setNumberOfCharts()
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    if (this.cancelEvent) {
      this.cancelEvent()
    }
  },
  methods: {
    getData() {
      if (this.cancelEvent) {
        this.cancelEvent()
      }

      const param = {}
      if (this.active) {
        param.period = this.active
      }

      if (this.settings.selectedPairsStr) {
        param.pairs = this.settings.selectedPairsStr
      }

      this.loadingData = true;

      const getURL = this.isActiveFutures
        ? `/api/v3/${this.currentScreenAndExchange.exchange}_perp/screener/graphs`
        : '/api/v2/screener/graphs';

      axios.get(getURL, {
        params: param,
        cancelToken: new axios.CancelToken((c) => {
          this.cancelEvent = c
        })
      })
        .then(res => {
          const arr = res?.data?.data
          if (arr && Array.isArray(arr)) {
            this.adapter.data = arr
          }

          this.loadingData = false

          const delay = res?.data?.delay
          this.startTimer(delay)
        })
        .catch((e) => {
          if (e instanceof axios.Cancel) {
            toastr.remove()
          } else if (e?.response?.status !== 401) {
            this.loadingData = false
            this.startTimer()
          }
        })
      // this.adapter.data = 'random'
    },
    startTimer(delay) {
      if (this.isPaused) return

      let localDelay = this.timerIntervalDefault
      if(this.isGuest){
        // каждую шестую минуту
        localDelay = 360000 - Date.now() % 300000
        if(localDelay < this.timerIntervalDefault){
          localDelay = this.timerIntervalDefault
        }
      } else if (delay && typeof delay === 'number') {
        localDelay = delay
      }

      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        if (this.loadingData) return
        this.getData()
      }, localDelay)
    },
    setNumberOfCharts() {
      const numberOfCharts = this.$store.state.settings?.graphsView?.numberOfCharts

      if (numberOfCharts) {
        this.numberOfCharts = numberOfCharts
      } else if (this.isDesktop) {
        this.numberOfCharts = 4
      }
    },
    ...mapActions('promo', ['toggleShowPromoModal']),
    openCandles (title) {
      // const access = this.$store.getters['user/getPermissions']('view_screener_graphs')
      // if (!access) return
      if(this.user.getAccess(1)){
        this.$nextTick(() => {
          this.$emit('openGraph')
          this.$refs.candles.openCandlesGraph(title, null)
        })
      }else{
        this.toggleShowPromoModal({show: true, content: { id: 'pro', sliderData: [{videoName: "linechart", isVideo: true}]}})
      }
    }
  },
  watch: {
    active(val) {
      if (val) {
        this.getData()
      }
    },
    'settings.selectedPairsStr': function (val) {
      if (val) {
        this.getData()
      }
    },
    loadingData(val) {
      this.$emit('changeLoading', val)
    },
    isCollapsed(val) {
      this.$store.dispatch('setKey', {
        namespace: 'graphsView',
        key: 'isCollapsed',
        value: val
      })
    },
    numberOfCharts(val) {
      if (!isNaN(val)) {
        this.$store.dispatch('setKey', {
          namespace: 'graphsView',
          key: 'numberOfCharts',
          value: val
        })
      }
    },
    isDesktop(val) {
      if (val) this.setNumberOfCharts()
    },
    isPaused(val) {
      if (val) {
        if (this.timer) {
          clearTimeout(this.timer)
        }
      } else {
        if (!this.loadingData) {
          this.getData()
        }
      }
    },
    isBrowserTabActive(val) {
      if (!val) {
        if (this.timer) {
          clearTimeout(this.timer)
        }
      } else {
        if (!this.loadingData && !this.isPaused) {
          this.getData()
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.graphs-wrap {
  overflow: hidden auto;
}

.switch-label {
  font-size: .625rem;

  @media (max-width: 350px) {
    display: none;
  }

  @include media-breakpoint-up(md) {
    font-size: .75rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: .875rem;
  }
}

.switch {
  margin-right: .3125rem;

  @include media-breakpoint-up(md) {
    margin-right: .5rem;
  }

  @include media-breakpoint-up(lg) {
    margin-right: 1rem;
  }
}

.switch-wrap {
  margin-right: .5rem;

  @media (max-width: 310px) {
    margin-right: 0;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: .5rem;
  }

  @include media-breakpoint-up(xl) {
    margin-right: 0;
  }
}

.list-wrap {
  @include media-breakpoint-up(xl) {
    padding-right: .5rem;
  }
}

.view-header {
  margin-bottom: .25rem;
}

.ticker {
  margin-bottom: .25rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: .5rem;
  }
}

.number-of-charts-select {
  /deep/ .form-control {
    background-color: var(--dark);
  }

  /deep/ label {
    white-space: nowrap;
  }
}
</style>
