var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-component", style: _vm.numberOfColumnsStyle },
    _vm._l(_vm.source.arr, function (item, i) {
      return _c(
        "div",
        { key: i, staticClass: "item" },
        [
          _c("graph-item", {
            attrs: {
              title: item.pair,
              start: item.open,
              current: item.close,
              delta: item.closePctStr,
              volatility: item.volatilityStr,
              color: item.color,
              width: item.width,
              "graph-x": item.x,
              "graph-y": item.y,
              "is-futures": _vm.isActiveFutures,
            },
            on: {
              clickTitle: function ($event) {
                return _vm.openCandles.call(null, item.pair)
              },
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }