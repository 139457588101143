<template>
  <div class="item-component" :style="numberOfColumnsStyle">
    <div
      v-for="(item, i) in source.arr"
      :key="i"
      class="item"
    >
      <graph-item
        :title="item.pair"
        :start="item.open"
        :current="item.close"
        :delta="item.closePctStr"
        :volatility="item.volatilityStr"
        :color="item.color"
        :width="item.width"
        :graph-x="item.x"
        :graph-y="item.y"
        :is-futures="isActiveFutures"
        @clickTitle="openCandles.call(null, item.pair)"
      />
    </div>
  </div>
</template>

<script>
import GraphItem from "@/components/screener/graphs/GraphItem";
import {createWatcherExhangeAndScreen} from "@/mixins/watch-exhange-and-screen";
import {SCREENER_PAGE_FUTURES} from "@/shared/constants";

export default {
  name: "ItemComponent",
  components: {
    GraphItem
  },
  mixins: [
    createWatcherExhangeAndScreen(({ getData }) => getData()),
  ],
  props: {
    source: {
      type: Object,
      default: () => ({})
    },
    openCandles: {
      type: Function
    }
  },
  computed: {
    numberOfColumnsStyle() {
      const numCol = this.source?.arr?.length || 1

      return `--columns: ${numCol}`
    },
    isActiveFutures() {
      return this.currentScreenAndExchange.screen === SCREENER_PAGE_FUTURES
    },
  }
}
</script>

<style lang="scss" scoped>
$columns: #{var(--columns, 1)};

@mixin gridTemplateColumns($gap) {
  grid-template-columns: repeat($columns, calc((100% - ((#{$columns} - 1) * #{$gap})) / #{$columns}));
  gap: $gap;
}

.item {
  width: 100%;

  @include media-breakpoint-up(sm) {
    margin-bottom: .5rem;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 1rem;
  }
}

.item-component {
  @include media-breakpoint-up(sm) {
    display: grid;
    @include gridTemplateColumns(.5rem);
  }

  @include media-breakpoint-up(xl) {
    @include gridTemplateColumns(1rem);
  }
}
</style>
