/** @import { CreateComponentPublicInstance } from 'vue' */
/** @typedef {(context: CreateComponentPublicInstance) => any} Callback */

/**
 * Создать наблюдатель для вызова колбэка при изменении биржи и/или типа экрана. Принимает
 * функции в которую передается контекст текущего компонента для доступа к объявленным
 * свойствам и методам. Дополнительно возвращает вычисляемое свойство для получения
 * текущей биржи и экрана.
 * @param {Callback} cb - Функция обратного вызова при изменении биржи и/или типа экрана.
 */
export const createWatcherExhangeAndScreen = (cb) => ({
  /** @this {CreateComponentPublicInstance} */
  mounted() {
    // !IMPORTANT
    // Создание общего наблюдателя для запроса данных при изменении биржи
    // и типа экрана. Это нужно что не дублировать запросы:
    const event = () => this.currentScreenAndExchange;
    const handler = () => cb(this);

    this.$watch(event, handler, { deep: true });
  },
  computed: {
    currentScreenAndExchange() {
      const screen = String(this.$store.getters.getLastScreen);
      const exchange = String(this.$store.getters.getLastExchange);
      return { screen, exchange };
    }
  }
})
