<template>
  <CCard ref="swiper" class="shadow-none card-custom swiper" color="second-card-bg">
    <CCardBody class="swiper-wrapper">
      <CButton
        v-for="(item, i) in settings.graphsPeriods"
        :key="i"
        :color="item === settings.graphsActivePeriod ? 'primary' : 'dark'"
        shape="pill"
        class="swiper-slide btn-custom font-weight-bold shadow-none"
        @click="settings.setGraphsActivePeriod(item)"
        size="sm"
      >
        {{item.toUpperCase()}}
      </CButton>
    </CCardBody>
  </CCard>
</template>

<script>
import moment from 'moment'
import Swiper from 'swiper'

export default {
  name: "ViewHeader",
  props: {
    settings: Object
  },
  data () {
    return {
      currentDateTime: null,
      timer: null,
      swiper: null
    }
  },
  computed: {
    currentDate () {
      if (!this.currentDateTime) return
      return this.currentDateTime.format('DD/MM/YYYY')
    },
    currentTime () {
      if (!this.currentDateTime) return
      return this.currentDateTime.format('HH:mm:ss')
    }
  },
  methods: {
    setCurrentDateTime () {
      this.currentDateTime = new moment()
    }
  },
  watch: {
    'settings.graphsPeriods': {
      deep: true,
      handler() {
        if (this.swiper) {
          try {
            this.swiper.update()
            // eslint-disable-next-line no-empty
          } catch (e) {}
        }
      }
    }
  },
  mounted () {
    if (this.$refs.swiper) {
      this.swiper = new Swiper(this.$refs.swiper, {
        slidesPerView: 'auto',
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
      })
    }
  },
  destroyed() {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/style";

.card-custom {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: fit-content;
  }
}

.btn-custom {
  margin-right: .5rem;
  width: auto;
  min-width: 3rem;
  padding: .25rem .5rem;
  font-size: .69rem;

  @include media-breakpoint-up(md) {
    min-width: 4.75rem;
  }

  @include media-breakpoint-up(lg) {
    margin-right: .625rem;
    min-width: 4.3rem;
    padding: .25rem .875rem;
    font-size: .75rem;
  }

  &:last-child {
    margin-right: 0;
  }
}

.date {
  color: $gray-800;
}

.swiper {
  padding: .25rem .875rem;
  margin: 0;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @include media-breakpoint-up(lg) {
    padding: .5rem 1.25rem;
  }
}

.swiper-wrapper {
  padding: 0;
  width: 100%;
}
</style>
