var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    {
      ref: "swiper",
      staticClass: "shadow-none card-custom swiper",
      attrs: { color: "second-card-bg" },
    },
    [
      _c(
        "CCardBody",
        { staticClass: "swiper-wrapper" },
        _vm._l(_vm.settings.graphsPeriods, function (item, i) {
          return _c(
            "CButton",
            {
              key: i,
              staticClass:
                "swiper-slide btn-custom font-weight-bold shadow-none",
              attrs: {
                color:
                  item === _vm.settings.graphsActivePeriod ? "primary" : "dark",
                shape: "pill",
                size: "sm",
              },
              on: {
                click: function ($event) {
                  return _vm.settings.setGraphsActivePeriod(item)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.toUpperCase()) + " ")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }