var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item" },
    [
      _c("div", {
        staticClass: "color rounded-pill mb-1",
        style: { background: _vm.color },
      }),
      _vm._v(" " + _vm._s(_vm.title) + " "),
      _vm.tooltip
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "c-popover",
                  rawName: "v-c-popover",
                  value: {
                    header: _vm.title,
                    content: _vm.tooltip,
                    appendToBody: true,
                  },
                  expression:
                    "{\n      header: title,\n      content: tooltip,\n      appendToBody: true,\n    }",
                },
              ],
              staticClass:
                "rounded-circle bg-primary tooltip-wrap d-inline-flex justify-content-center",
            },
            [_vm._v(" ? ")]
          )
        : _vm._e(),
      _vm.keyCol
        ? _c("CIcon", {
            staticClass: "switcher",
            class: {
              _active: _vm.sorting,
              _reverse: _vm.sorting === "asd",
            },
            attrs: { width: "0.7rem", name: "arrow-strong" },
            nativeOn: {
              click: function ($event) {
                return _vm.changeSort.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }