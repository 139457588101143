var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    {
      ref: "swiper",
      staticClass: "card-custom shadow-none swiper",
      attrs: { color: "second-card-bg" },
    },
    [
      _c(
        "CCardBody",
        { staticClass: "swiper-wrapper" },
        [
          _c("ticker-name", {
            staticClass: "swiper-slide",
            attrs: { "key-col": "pair" },
          }),
          _c("ticker-item", {
            staticClass: "swiper-slide",
            attrs: {
              color: "#FFC107",
              title: _vm.$t("screener.graphsView.open"),
            },
          }),
          _c("ticker-item", {
            staticClass: "swiper-slide",
            attrs: {
              color: "#20A8D8",
              title: _vm.$t("screener.graphsView.current"),
            },
          }),
          _c("ticker-item", {
            staticClass: "swiper-slide",
            attrs: {
              "key-col": "closePct",
              color: "#C8CED3",
              title: _vm.$t("screener.graphsView.change"),
            },
          }),
          _c("ticker-item", {
            staticClass: "swiper-slide",
            attrs: {
              "key-col": "volatility",
              color: "#E83E8C",
              title: _vm.$t("screener.graphsView.volat"),
              tooltip: _vm.$t("screener.graphsView.volatDesc"),
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }