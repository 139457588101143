var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    { staticClass: "card shadow-none" },
    [
      _c(
        "CCardHeader",
        { staticClass: "c-card-header d-flex bg-dark position-relative" },
        [
          _c("watch-selector", {
            staticClass: "watch-selector",
            attrs: {
              ticker: _vm.title,
              "popover-options": {
                popoverClass: "v-tooltip popover--header",
              },
              "is-futures": _vm.isFutures,
            },
          }),
          _c(
            "span",
            {
              staticClass: "font-weight-bold mr-2 overflow-hidden title-custom",
              on: {
                click: function ($event) {
                  return _vm.$emit("clickTitle")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
          _c("div", { staticClass: "mx-auto d-flex" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", {
                staticClass: "color",
                style: { background: "#FFC107" },
              }),
              _vm._v(" " + _vm._s(_vm.start) + " "),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", {
                staticClass: "color",
                style: { background: "#20A8D8" },
              }),
              _vm._v(" " + _vm._s(_vm.current) + " "),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", {
                staticClass: "color",
                style: { background: "#C8CED3" },
              }),
              _vm._v(" " + _vm._s(_vm.delta) + " "),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", {
                staticClass: "color",
                style: { background: "#E83E8C" },
              }),
              _vm._v(" " + _vm._s(_vm.volatility) + " "),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "CCardBody",
        { staticClass: "px-0 py-1" },
        [
          _c("chart-item", {
            attrs: {
              labels: _vm.graphX,
              "data-points": _vm.graphY,
              "border-color": _vm.color,
              "border-width": _vm.width,
            },
            on: {
              click: function ($event) {
                return _vm.$root.$emit("ChartItemGraphScreenerClick", _vm.title)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }