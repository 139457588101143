<template>
  <div class="item">
    <div class="color rounded-pill mb-1" :style="{background: color}"></div>
    {{title}}
    <span
      class="rounded-circle bg-primary tooltip-wrap d-inline-flex justify-content-center"
      v-if="tooltip"
      v-c-popover="{
        header: title,
        content: tooltip,
        appendToBody: true,
      }"
    >
      ?
    </span>
    <CIcon
      v-if="keyCol"
      width="0.7rem"
      class="switcher"
      :class="{
        _active: sorting,
        _reverse: sorting === 'asd'
      }"
      name="arrow-strong"
      @click.native="changeSort"
    />
  </div>
</template>

<script>
import screenerGraphAdapter from "@/assets/js/screenerGraphAdapter"
export default {
  name: "TickerItem",
  props: {
    keyCol: String,
    color: String,
    title: String,
    tooltip: String
  },
  data () {
    return {
      adapter: screenerGraphAdapter
    }
  },
  computed: {
    sorting () {
      if (
        this.adapter.sortCol === this.keyCol &&
        ['asd', 'desc'].indexOf(this.adapter.sortType) !== -1
      ) {
        return this.adapter.sortType
      } else {
        return null
      }
    }
  },
  methods: {
    changeSort () {
      if (this.adapter.sortCol === this.keyCol) {
        if (!this.sorting) {
          this.adapter.sortType = 'desc'
        } else if (this.sorting === 'desc') {
          this.adapter.sortType = 'asd'
        } else {
          this.adapter.sortType = null
        }
      } else {
        this.adapter.sortCol = this.keyCol
        this.adapter.sortType = 'desc'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
}
.color {
  width: 100%;
  height: 2px;
}
.tooltip-wrap {
  margin-left: 0.3rem;
  cursor: pointer;
  color: #fff;
  width: 1rem;
  height: 1rem;
}
.switcher {
  margin-left: 0.3rem;
  width: 0.7rem;
  height: 0.7rem;
  cursor: pointer;
  z-index: 1;
  opacity: 0.25;
  transition: all 250ms ease-out;
  &:hover {
    opacity: 0.35;
  }
  &._active {
    opacity: 1;
  }
  &._reverse {
    transform: rotate(180deg);
  }
}

.item {
  font-size: .75rem;
}
</style>
