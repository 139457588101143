<template>
  <CCard class="card shadow-none">
    <CCardHeader class="c-card-header d-flex bg-dark position-relative">
      <watch-selector
        :ticker="title"
        :popover-options="{
          popoverClass: 'v-tooltip popover--header',
        }"
        :is-futures="isFutures"
        class="watch-selector"
      />

      <span
        class="font-weight-bold mr-2 overflow-hidden title-custom"
        @click="$emit('clickTitle')"
      >
        {{ title }}
      </span>
      <div class="mx-auto d-flex">
        <div class="item">
          <div class="color" :style="{background: '#FFC107'}"></div>
          {{start}}
        </div>
        <div class="item">
          <div class="color" :style="{background: '#20A8D8'}"></div>
          {{current}}
        </div>
        <div class="item">
          <div class="color" :style="{background: '#C8CED3'}"></div>
          {{delta}}
        </div>
        <div class="item">
          <div class="color" :style="{background: '#E83E8C'}"></div>
          {{volatility}}
        </div>
      </div>
    </CCardHeader>
    <CCardBody class="px-0 py-1">
      <chart-item
        :labels="graphX"
        :data-points="graphY"
        :border-color="color"
        :border-width="width"
        @click="$root.$emit('ChartItemGraphScreenerClick', title)"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import ChartItem from "@/components/screener/graphs/ChartItem";
import WatchSelector from "@/components/screener/WatchSelector";
export default {
  name: "GraphItem",
  props: {
    title: String,
    start: Number,
    current: Number,
    width: Number,
    delta: String,
    volatility: String,
    color: String,
    graphX: Array,
    graphY: Array,
    isFutures: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ChartItem,
    WatchSelector
  }
}
</script>

<style lang="scss" scoped>

.card {
  font-size: 0.75rem;
  background: var(--second-card-bg);
  min-height: 200px;
  border: 1px solid var(--scroll-handler) !important;
  margin: 0;
}
.c-dark-theme {
  .card {
    border: none !important;
  }
}
.item {
  position: relative;
  margin-right: 0.5rem;
  &:last-child {
    margin-right: 0;
  }
  .color {
    position: absolute;
    width: 100%;
    height: 2px;
    top: -0.35rem;
    left: 0;

    @include media-breakpoint-up(lg) {
      top: -0.7rem;
    }

    .primary {
      background: var(--primary);
    }
  }
}

.title-custom {
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.watch-selector {
  z-index: 1;
}

.c-card-header {
  padding: 0.375rem 1.25rem;

  @include media-breakpoint-up(lg) {
    padding: 0.75rem 1.25rem;
  }
}
</style>
