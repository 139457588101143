<template>
  <div @click="$emit('click')" class="position-relative">
    <canvas ref="lineChart" width="400" height="150"></canvas>
  </div>
</template>

<script>
import {
  Chart,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  LineController,
  Tooltip
} from 'chart.js';

Chart.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  LineController,
  Tooltip
);

export default {
  name: 'ChartItem',
  props: {
    labels: {
      type: Array,
      default: () => ([])
    },
    borderColor: {
      type: String,
      default: 'rgba(255,255,255,.55)'
    },
    borderWidth: {
      type: Number,
      default: 1
    },
    dataPoints: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      options: {
        interaction: {
          mode: 'index',
          intersect: false
        },
        responsive: true,
        scales: {
          y: {
            display: false
          },
          x: {
            display: false
          },
        },
        elements: {
          line: {
            tension: 0
          },
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4
          }
        },
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
            chartElement: null
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                return context.raw >= 1 ? context.formattedValue : context.raw.toString().replace('.', ',')
              },
            },
          },
        },
      },
    }
  },
  computed: {
    chartData() {
      return {
        datasets: this.datasets,
        labels: this.labelsMapped
      }
    },
    datasets() {
      return [{
        label: this.$t('general.price'),
        data: this.dataPoints,
        backgroundColor: this.borderColor,
        pointHoverBackgroundColor: this.borderColor,
        borderColor: this.borderColor,
        borderWidth: this.borderWidth,
      }]
    },
    chartConfig() {
      return {
        type: 'line',
        data: this.chartData,
        options: this.options,
      }
    },
    labelsMapped() {
      return this.labels.map(el => {
        if (el) {
          return el.split(' ')[0]
        } else return ''
      })
    },
  },
  watch: {
    labels: {
      deep: true,
      handler() {
        this.updateLabels()
      }
    },
    dataPoints: {
      deep: true,
      handler() {
        this.updateData()
      }
    },
    borderColor() {
      this.updateData()
    },
    borderWidth() {
      this.updateData()
    },
  },
  mounted() {
    this.chartElement = new Chart(
      this.$refs.lineChart,
      this.chartConfig
    );
  },
  methods: {
    updateChart() {
      if (this.chartElement) {
        this.chartElement.update('resize')
      }
    },
    updateLabels() {
      if (this.chartElement) {
        this.chartElement.data.labels = this.labelsMapped
        this.updateChart()
      }
    },
    updateData() {
      if (this.chartElement) {
        this.chartElement.data.datasets = this.datasets
        this.updateChart()
      }
    },
  }
}
</script>
