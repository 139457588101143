var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "position-relative",
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [_c("canvas", { ref: "lineChart", attrs: { width: "400", height: "150" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }